/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        /****************
        **  External links new window
        *****************/
        $('a').each(function() {
			   var a = new RegExp('/' + window.location.host + '/');
			   if (!a.test(this.href)) {
			   	$(this).attr("rel","external").attr("target","_blank");
			   }
				});


        /****************
        **  SEARCH overlay
        *****************/
        $('.toggle-overlay,.menu-search-site a,.menu-search-site').click(function(e) {
          $('#search-overlay').toggleClass('overlay-open');
          return false;
        });

        /****************
        **  Match heights
        *****************/
        $('.services-grid .tile article header').matchHeight();
        $('.services-grid .tile article .excerpt').matchHeight();

        $('.entry-grid .tile article span.categories').matchHeight();
        $('.entry-grid .tile article .entry-summary').matchHeight();

        /****************
        **  Add caret to end of buttons
        *****************/
        $('.wp-block-button__link').append(' &raquo;');

        /****************
        **  Slick carousels
        *****************/
        $('.cav-gallery.button-blue').slick({
          dots: false,
          infinite: true,
          autoplay: false,
          rows: 0,
          prevArrow:"<img class='a-left control-c prev slick-prev' src='/wp-content/themes/citizen/dist/images/arrow-left-blue.png'>",
          nextArrow:"<img class='a-right control-c next slick-next' src='/wp-content/themes/citizen/dist/images/arrow-right-blue.png'>",
        });
        $('.cav-gallery.button-white').slick({
          dots: false,
          infinite: true,
          autoplay: false,
          rows: 0,
          prevArrow:"<img class='a-left control-c prev slick-prev' src='/wp-content/themes/citizen/dist/images/arrow-left-white.png'>",
          nextArrow:"<img class='a-right control-c next slick-next' src='/wp-content/themes/citizen/dist/images/arrow-right-white.png'>",
        });
        $('.cav-gallery').slickLightbox();

        /****************
        **  Clone sidebar elements for Services mobile elements
        *****************/
        $( ".sidebar-wrap" ).clone().appendTo( "#nextsteps-mobilewrap" );
        $( ".view-services" ).clone().appendTo( "#viewservices-mobilewrap" );
        $( "#viewservices-mobilewrap h3" ).remove();
        $( "#viewservices-mobilewrap #collapseServices" ).attr('id','').removeClass('collapse');

        $('nav.service-mobile a').click( function(e) {
            $('.mobile-services .collapse').collapse('hide');
        });

        /****************
        **  SVG swap out with PNG
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        /****************
        **  make phone numbers links
        *****************/
//         var phoneregex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g;


//         $("body").html($("body").html().replace(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g,'<a href="tel:1'+$&.replace("-", "")+'" class="phonelink">$&</a>'));


//         var phonetext = $("body:first").html();
/*
        phonetext = phonetext.replace(phoneregex, "<a href=\"tel:$&\" class=\"phonelink\">$&</a>");
        $("body:first").html(phonetext);
*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.offerings .offering article header').matchHeight({ byRow: false });
        $('.offerings .offering article .details').matchHeight();

        $('.hero-gallery').slick({
          dots: false,
          infinite: true,
          autoplay: true,
          rows: 0,
          arrows: false,
          fade: true,
          cssEase: 'linear',
          speed: 400,
          autoplaySpeed: 5000
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'therapists': {
      init: function() {

        $('.therapist-filters select').change(function(){
          $('.therapist-grid .therapist-item').hide();
          var LocationValue= $('.therapist-filters select#location option:selected').val();
          var LocationText= $('.therapist-filters select#location option:selected').text();
          var MeetingFormatValue= $('.therapist-filters select#meeting-format option:selected').val();
          var MeetingFormatText= $('.therapist-filters select#meeting-format option:selected').text();
          var GenderValue= $('.therapist-filters select#gender option:selected').val();
          var GenderText= $('.therapist-filters select#gender option:selected').text();

          $('.therapist-grid .therapist-item[data-location*="'+LocationValue+'"][data-meeting-format*="'+MeetingFormatValue+'"][data-gender*="'+GenderValue+'"]').fadeIn(250);

          if (LocationValue!=="all-locations" || MeetingFormatValue!=="all-formats" || GenderValue!=="all-genders") {
            $('#clear-all').removeClass('hidden');
          } else {
            $('#clear-all').addClass('hidden');
          }
        });

        $('.therapist-filters a#clear-all').click(function(e){
          e.preventDefault();
          $('.therapist-filters select').prop("selectedIndex", 0);
          $('.therapist-grid .therapist-item').fadeIn(250);
          $('.therapist-filters a#clear-all').addClass('hidden');
          return false;
        });


      }
    },
    'single_therapist': {
      init: function() {
        $( "#breadcrumbs span.breadcrumb_last" ).before( "<span><a href='/therapists/'>Meet Our Therapists</a></span> &nbsp;\\&nbsp; " );
      }
    },
    'search': {
      init: function() {
        $('.banner .menu-north-country-news').removeClass('active');
      }
    },
    'archive': {
      init: function() {
        $('#menu-news-category-navigation .menu-view-all').removeClass('active');
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
